import { useState, useEffect } from 'react';
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Paging,
    Pager,
    SearchPanel,
    GroupItem,
    Summary
} from 'devextreme-react/data-grid';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Button from '@mui/material/Button';
import {     
    ButtonGroup, 
    CircularProgress, 
    FormGroup, 
    Grid, 
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

import { logExport, useAuth } from '../hooks/useAuth';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch.js';
import CustomStore from 'devextreme/data/custom_store';
import roundNumberFormatter from '../components/RoundNumberFormatter';
import ExportGrid from '../components/ExportGrid';
import ExportCRMAGrid from '../components/ExportCRMAGrid';
import { pageFormats } from "../components/GlobalDataGridConfigurations";
import FiscalYearMultiSelectDevEx from './TotalBuildingCostReportComponents/FiscalYearMultiSelectDevEx.js';
import RegionMultiSelectDevEx from './TotalBuildingCostReportComponents/RegionMultiSelectDevEx.js' ;
import BuildingMultiselectDevEx from './TotalBuildingCostReportComponents/BuildingMultiselectDevEx.js' ;
import { FOUOfooter, FOUOheader } from '../components/FOUO';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { CustomAccordion } from '../components/CustomAccordion/CustomAccordion';
import { MultiSelectCheckBoxListInstructions } from '../CustomAccordionItemList/SelectListInstructions';
import ClosingAlert from '../components/ClosingAlert';
import "./TotalBuildingCostReport.scss";
export default function TotalBuildingCostReport() {
    const [loading, setLoading] = useState(false);
    const [buildingList, setBuildingList] = useState(null);
    const [filteredBuildings, setFilteredBuildings] = useState(null)
    const [bldError, setBldError] = useState(false);
    const [bldErrorMessage, setBldErrorMessage] = useState();
    const [dataError, setDataError] = useState(false);
    const [fiscalYearsList, setFiscalYear] = useState([]);
    const [error, setFYError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [selectedFiscalYears, setSelectedFiscalyears] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [submitQuery, setSubmitQuery] = useState(false);
    const [querySubmitted, setQuerySubmitted] = useState(false);
    const { user } = useAuth();
    
    useEffect(() => {
        document.title = "Total Building-Specific Cost Report";
    }, []);

    useEffect(() => {
        const fiscalYearNotNull = selectedFiscalYears.length > 0;
        const regionsNotNull = selectedRegions.length > 0;
        setSubmitQuery(fiscalYearNotNull && regionsNotNull );
    }, [selectedFiscalYears, selectedRegions])

    const regionsObject = (r,b,f) => {
        var jsonObj = {
            RegionListDomain: r.replace(/,$/, "").split(",").map(function (r) {
                return { Region: r };
            }),
            BuildingDomain: b =='' ? []: b.replace(/,$/, "").split(",").map(function (b) {
                return { Building: b };
            }),
            FiscalYearDomain: f.replace(/,$/, "").split(",").map(function (f) {
                return { Year: f };
            })
        }
        return jsonObj;
    }
    const handleSubmit = async () => {
        setQuerySubmitted(true);
    };

    (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        };
    };

    const formatTotalRow = (value) => {
        return `${value}-Total`
    };

    const buidlingData = new CustomStore({
        key: 'ID',
        load: async () => {
            if (submitQuery) {
                const buildingNumbers =  selectedBuildings ;  
                let stringOfRegions = regionsObject(selectedRegions.toString(), buildingNumbers.toString(), selectedFiscalYears.toString());
                const data = await PutPostPatchFetch('/v1/BuildSpecReport/RegionListDomain', 'PUT', stringOfRegions);
                const { Message, Success, Errors } = data ?? {};

                if (Success === false) {
                    setDataError(true)                   
                    console.error(Errors)
                }
                if (Message) {                 
                    let i = -1;
                    const buildingCostReport = Message.map(region =>
                        region.Costs.map(building =>
                            building.Costs.map(year => {
                                i++;
                                return {
                                    ID: building.BuildingSequenceNumber + year.Year + i,
                                    regionNumber: region.RegionNumber,
                                    buildingNumber: building.BuildingSequenceNumber,
                                    ansiRent: building.AnsiRent,
                                    psoCost: Math.round(year.PsoCharges),
                                    tcmCost: Math.round(year.TcmCharges),
                                    amortCapCost: Math.round(year.AmortCharges),
                                    total: Math.round(year.TotalCharges),
                                    fiscalYear: year.Year,

                                };
                            })));
                    let bldgList = buildingCostReport.flat(5);

                    return bldgList;
                };
            }
        }
    });

    const crmaData = new CustomStore({
        key: 'ID',
        load: async () => {
            if (submitQuery) {               
                 let stringOfRegions = regionsObject(selectedRegions.toString(), selectedBuildings.toString(), selectedFiscalYears.toString());
                const data = await PutPostPatchFetch('/v1/BuildSpecReport/RegionListDomain', 'PUT', stringOfRegions);
                const { Message, Success, Errors } = data ?? {};

                if (Success === false) {
                    setDataError(true)
                    console.error(Errors)
                }              
                if (Message) {
                    let i = -1;
                    const crmaCostReport = Message.map(region =>
                        region.Costs.map(building =>
                            building.Costs.map(year => {
                                i++;
                                return {
                                    ID: building.BuildingSequenceNumber + year.Year + i,
                                    fiscalYear: year.Year,
                                    regionNumber: region.RegionNumber,
                                    buildingNumber: building.BuildingSequenceNumber,
                                    ansiRent: building.AnsiRent,
                                    psoCost: roundNumberFormatter.format(year.PsoCharges),
                                    psoUndist: roundNumberFormatter.format(0),
                                    psoAdmin: roundNumberFormatter.format(0),
                                    psoTotal: roundNumberFormatter.format(year.PsoCharges),
                                    tcmCost: roundNumberFormatter.format(year.TcmCharges),
                                    tcmUndist: roundNumberFormatter.format(0),
                                    tcmAdmin: roundNumberFormatter.format(0),
                                    tcmTotal: roundNumberFormatter.format(year.TcmCharges),
                                    bldTotal: roundNumberFormatter.format(year.PsoCharges + year.TcmCharges),
                                    amortCapCost: roundNumberFormatter.format(year.AmortCharges)
                                };
                            })));
                    let crmaList = crmaCostReport.flat(5);
                    return crmaList;
                };
            }
        }
    });

    const allowedPageSizes = [10, 20, 30];
    const displayBuildings = [{ RegionNumber: 0, SequenceNumber: "All" }].concat(filteredBuildings)
    const selectedFiscalYearsMap = selectedFiscalYears.map((y) => { return y + ',' })

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e,
            `Total_Building_Specific_Cost_Report-Fiscal_Years(${selectedFiscalYearsMap})`
        )
    };
    const pdfFont = ()=>
    {    return 8;   }
    const wrapPdfCell = ()=>{
     return true;   }

    const handleCRMAExport = (e) => {
        logExport(user.email)
        ExportCRMAGrid(e,
            "FPSDS_GSA",
            wrapPdfCell(),
            pdfFont()
        )
    };

    const handleItemSelection = ({value}) => {
        if (value.length > 0) {
            setSelectedRegions(value);
        };
    };

    const combinedOnCellPrepared = (e) => {
        CustomPreparedHeaderCell(e);
    
        if (e.rowType === 'group') {
            const buildingName = e.data?.key;
            const isCollapsed = e.cellElement.querySelector('.dx-datagrid-group-closed');
            const ariaLabel = isCollapsed ? `Expand ${buildingName}` : `Collapse ${buildingName}`;
            const ariaExpanded = isCollapsed ? 'false' : 'true';
    
            e.cellElement.setAttribute('aria-label', ariaLabel);
            e.cellElement.setAttribute('aria-expanded', ariaExpanded);
        }
    };    

    return (
        <div>
            <Typography component="div">
                <h1 style={{ color: "#06152B" }}>Building-Specific Cost Report</h1>
            </Typography>
            <Typography
                variant="body1"
                component="em"
                style={{ color: "#06152B" }}
            >
                Please select a time range, fiscal year(s), region(s), and building number(s)
            </Typography>
            <br />

            <CustomAccordion
                itemList={<MultiSelectCheckBoxListInstructions/> }

            />
            <br />
            {/* {filteredBuildings &&  */}
            <FormGroup>
                <ButtonGroup
                    disableElevation
                    variant="contained"
                    sx={{ marginBottom: '3px' }}
                >
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <Grid item>
                            <FiscalYearMultiSelectDevEx
                                value={selectedFiscalYears}
                                valueSetter={setSelectedFiscalyears}
                            />
                        </Grid>
                        <Grid item>
                            <RegionMultiSelectDevEx
                                value={selectedRegions}
                                valueSetter={setSelectedRegions}
                            />
                        </Grid>
                        <Grid item>
                            <BuildingMultiselectDevEx
                                value={selectedBuildings}
                                valueSetter={setSelectedBuildings}
                                selectedRgns={selectedRegions}
                                dependentOnRegion = {true}
                                allAllowed = {false}
                                req = {false}
                               
                            />
                             

                        </Grid>
                       
                        <Box width="100%" />
                        <Grid item>
                            {(submitQuery) &&
                                <Button
                                    id="submit-button"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    startIcon={<QueryStatsRoundedIcon />}
                                >
                                    Search Costs
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </ButtonGroup>
                <ClosingAlert
                    severity="error"
                    message={bldErrorMessage}
                    visible={bldError && !loading}
                    hideAlert={() => { setBldError(false) }}
                />
            </FormGroup>

            {
                (loading) &&
                <CircularProgress color="secondary" />
            }
            {
                (querySubmitted) &&
                <Box sx={{ height: 600, width: '97%', margin: 'auto' }}>
                    <Paper
                        sx={{
                            padding: '30px'
                        }}
                    >
                        <TabPanel id="tabPanel">
                            <Item title="Building-Specific Cost Report" id="building-specific-tab" aria-label="building-specific-tab">
                                <br />
                                <FOUOheader
                                    dateString={new Date().toString()}
                                    fiscalYearsBoolean={true}
                                    fiscalYears={selectedFiscalYearsMap}
                                    heading={"Building-Specific Cost"}
                                    userEmail={user.email}
                                />
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={buidlingData}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    onCellPrepared={combinedOnCellPrepared}
                                    onExporting={e => handleExport(e)}
                                    style={{color: "#06152B" }}
                                >
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel
                                        highlightCaseSensitive={true}
                                        visible={true}
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        allowedPageSizes={allowedPageSizes}
                                        showPageSizeSelector={true}
                                    />
                                    <Column dataField="regionNumber" caption="Region" groupIndex={0} showWhenGrouped={true} />
                                    <Column dataField="buildingNumber" caption="Building Number" groupIndex={1} showWhenGrouped={true} />
                                    <Column dataField="ansiRent" caption="ANSI Rent" format="fixedPoint" />
                                    <Column dataField="psoCost" caption="PSO Cost" format="currency" />
                                    <Column dataField="tcmCost" caption="TCM Cost" format="currency" />
                                    {/* <Column dataField="amortCapCost" caption="AMORT Cap Cost" /> */}
                                    <Column dataField="total" caption="Total" format="currency" />
                                    <Column dataField="fiscalYear" caption="Fiscal Year" />
                                    <Summary>
                                        <GroupItem
                                            column="psoCost"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            alignByColumn={true} />
                                        <GroupItem
                                            column="tcmCost"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            alignByColumn={true} />
                                        <GroupItem
                                            column="total"
                                            summaryType="sum"
                                            valueFormat="currency"
                                            alignByColumn={true} />
                                    </Summary>
                                    <Export
                                        enabled={true}
                                        formats={pageFormats}
                                    />
                                </DataGrid>
                                <FOUOfooter />
                            </Item>
                            <Item title="CRMA Formatted Report" id="crma-report-tab" aria-label="crma-report-tab">
                                <FOUOheader
                                    dateString={new Date().toString()}
                                    fiscalYearsBoolean={true}
                                    fiscalYears={selectedFiscalYearsMap}
                                    heading={"Building-Specific Cost"}
                                    userEmail={user.email}
                                />
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={crmaData}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    onExporting={e => handleCRMAExport(e)}
                                >
                                    <KeyboardNavigation enabled={true} />
                                    <SearchPanel
                                        highlightCaseSensitive={true}
                                        visible={true}
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        allowedPageSizes={allowedPageSizes}
                                        showPageSizeSelector={true}
                                    />
                                    <Column dataField="regionNumber" caption="Region" />
                                    <Column dataField="fiscalYear" caption="Fiscal Year" />
                                    <Column dataField="buildingNumber" caption="Location" />
                                    <Column dataField="ansiRent" caption="ANSI Soft" format="fixedPoint" />
                                    <Column dataField="psoCost" caption="Pso Charge K20" />
                                    <Column dataField="psoUndist" caption="K20 Undist. Amt." />
                                    <Column dataField="psoAdmin" caption="K20 Admin Amt." />
                                    <Column dataField="psoTotal" caption="K20 Total" />
                                    <Column dataField="tcmCost" caption="Tcm Charge K30" />
                                    <Column dataField="tcmUndist" caption="K30 Undist. Amt." />
                                    <Column dataField="tcmAdmin" caption="K30 Admin Amt." />
                                    <Column dataField="tcmTotal" caption="K30 Total" />
                                    <Column dataField="bldTotal" caption="Bldg Specific Total" />
                                    <Column dataField="amortCapCost" caption="Amort Capital" />
                                    <Export
                                        enabled={true}
                                        formats={pageFormats}
                                    />
                                </DataGrid>
                                <FOUOfooter />
                            </Item>
                        </TabPanel>
                    </Paper>
                </Box>
            }
        </div >
    )
};